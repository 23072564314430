<template>
    <div>
        <!-- start page title -->
		<div class="row mt-4 mb-3">
			<div class="col-12">
				<div class="page-title-box">
					<h4 class="page-title">Профиль</h4>
				</div>
			</div>
		</div>

        <router-view></router-view>
    </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
    name: 'ProfileLayout',
};
</script>

<style>
.page-title {
    line-height: 1rem !important;
}
</style>